import React, { useState } from "react"
import { navigate } from "gatsby"
import Layout from "../components/Layout.en"
import PageTitle from "../components/PageTitle"
import GradientImage from "../images/gradient-big.png"

// Images
// import KsoeLogo from "../images/ksoe-logo.png"
// import BlueLabel from "../images/logo-blue.png"
// import KSVLogo from "../images/ksv.png"
import ManagementModelImg from "../images/management-model-en.png"

const GovernanceModel = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <React.Fragment>
      <PageTitle title={"Governance model"} />
      <Layout>
        <div className="management-model-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/en")}>Home</a>
              </li>
              <li>
                <a>Governance model</a>
              </li>
            </ul>
          </div>

          <div className="managment-model">
            <h1>Governance model</h1>
            {/* <div className="managment-model__wrapper">
              <div className="page__flex">
                <div className="managment-model__box"></div>
                <div className="managment-model__box">
                  <div className="managment-model__box--item">
                    <div className="managment-model__box--block">
                      <div className="name">Associated organisations</div>
                      <div className="managment-model__box--image">
                        <a
                          href="https://kuratorium-sicheres-oesterreich.at/"
                          className="image"
                          target="_blank"
                        >
                          <img src={KsoeLogo} className="mw-100"></img>
                        </a>
                        <div className="image">
                          <img src={BlueLabel} className="mw-100"></img>
                        </div>
                      </div>
                      <div className="desc">
                        <img src={KSVLogo} className="mw-100"></img>
                      </div>
                    </div>
                    <div className="managment-model__box--desc second">
                      <div className="item item__down">
                        Management of associated organisations nominates each
                        one representative
                      </div>
                      <div className="item item__up">Report</div>
                    </div>
                  </div>
                  <div className="managment-model__box--item">
                    <div className="managment-model__box--main">
                      <div className="name">
                        Operators of essential services
                      </div>
                      <ul>
                        <li>Banks</li>
                        <li>Healthcare</li>
                        <li>Energy</li>
                        <li>Transport</li>
                        <li>Financial market infrastructure</li>
                        <li>Digital infrastructure</li>
                        <li>Water supply</li>
                        <li>Public administration</li>
                      </ul>
                    </div>
                    <div className="managment-model__box--desc">
                      <div className="item item__down">
                        One representative for each sector
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="managment-model__block">
                <div className="page__flex">
                  <div className="managment-model__block--item">
                    <div className="managment-model__block--box">
                      <div className="name">Strategic</div>
                      <div className="text">(CRR-/Label-Scheme)</div>
                    </div>
                    <div className="managment-model__block--box">
                      <div className="name">Operational</div>
                      <div className="text">(CRR-/Label-Scheme)</div>
                    </div>
                  </div>
                  <div className="managment-model__block--item">
                    <div className="managment-model__block--blue">
                      <div className="name">Cyber Risk Management Board</div>
                      <div className="text">
                        <p>
                          Develops proposal for the CRR-Scheme and the Cyber
                          Risk Label accordingly
                        </p>
                      </div>
                      <div className="desc">
                        <p>
                          Escalation instance in case of disputes regarding
                          rating or label qualification
                        </p>
                        <p>
                          Evaluations after withdrawal of the rating / label
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="managment-model__block--item">
                    <div className="managment-model__block--inner">
                      <div className="item item__right">Proposes</div>
                      <div className="item item__left">Approves</div>
                    </div>
                    <div className="managment-model__block--inner">
                      <div className="item item__right">Yearly Report</div>
                    </div>
                  </div>
                  <div className="managment-model__block--item">
                    <div className="managment-model__block--gold">
                      <div className="name">Cyber Risk Advisory Board</div>
                      <div className="text">
                        <p>Definition of requirements</p>
                        <p>
                          Approval of CRR-Scheme and the Cyber Risk Label
                          accordingly
                        </p>
                      </div>
                      <div className="desc">
                        <p>Control instance</p>
                        <p>Scheme corrections on demand</p>
                      </div>
                    </div>
                  </div>
                  <div className="managment-model__block--item">
                    <div className="managment-model__block--desc">
                      <span>powered by:</span>
                      <a
                        href="https://kuratorium-sicheres-oesterreich.at/"
                        target="_blank"
                      >
                        <img
                          src={KsoeLogo}
                          className="mw=100"
                          style={{ maxWidth: "92px" }}
                        ></img>
                      </a>
                    </div>
                    <div className="managment-model__block--desc"></div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

            <div className="managment-model__text-wrapper">
              <div className="text">
                <p>
                  The governance of the cyber risk rating schemes lies in the
                  hands of the Cyber Risk Advisory Board, which consists of
                  leading cybersecurity experts from large Operators of
                  essential Services from all sectors according to the NIS
                  directive (banks, energy providers, healthcare providers,
                  digital services, etc.). Additionally, there are
                  representatives of the competent NIS authority in the Cyber
                  Risk Advisory Board. Therefore it is not only guaranteed that
                  the requirements of the Cyber Risk Rating are technically
                  according to state of the art, but they also comply with the
                  security requirements of large enterprises as well as the
                  competent authorities.
                </p>
                <p>
                  The scheme is subject to an ongoing review and improvement, to
                  reflect the ever changing requirements of the cybersecurity
                  domain.
                </p>
              </div>
            </div>

            <div className="zoom__wrapper">
              <div
                className="zoom__img__wrapper"
                onClick={() => setIsOpen(!isOpen)}
              >
                <svg
                  width="29"
                  height="28"
                  viewBox="0 0 29 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_213_11781)">
                    <path
                      d="M20.8511 19.6593C22.6383 17.5741 23.8298 14.794 23.8298 11.8153C23.8298 5.26209 18.4681 -0.0996094 11.9149 -0.0996094C5.3617 -0.0996094 0 5.36138 0 11.9146C0 18.4678 5.3617 23.8295 11.9149 23.8295C15.1915 23.8295 18.0709 22.5387 20.2553 20.4536L27.8014 27.9997L28.4965 27.3046L20.8511 19.6593ZM11.9149 22.8366C5.85816 22.8366 0.992908 17.872 0.992908 11.9146C0.992908 5.95713 5.85816 0.992589 11.9149 0.992589C17.9716 0.992589 22.8369 5.85784 22.8369 11.9146C22.8369 17.9713 17.8723 22.8366 11.9149 22.8366Z"
                      fill="white"
                    />
                    <path
                      d="M17.1773 11.4183V12.4112H12.4113V17.1772H11.4184V12.4112H6.65247V11.4183H11.4184V6.65234H12.4113V11.4183H17.1773Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_213_11781">
                      <rect width="28.3972" height="28" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>

            <div className="managment-model__wrapper">
              <input
                type="checkbox"
                id="zoomCheck"
                checked={isOpen}
                onChange={() => {}}
              ></input>
              <label htmlFor="zoomCheck">
                <img
                  src={ManagementModelImg}
                  alt="Management Model"
                  title="Management Model"
                  className="mw-100"
                  onClick={() => setIsOpen(!isOpen)}
                ></img>
              </label>
            </div>

            <div className="asked-question__block--contact">
              <div>
                <h2>
                  <b>
                    Do you want to know more about Cyber ​​Trust Austria?
                    Contact us.
                  </b>
                </h2>
              </div>
              <div>
                <div>
                  <a
                    style={{
                      // border: "4px solid",
                      padding: "10px 10px",
                      width: 275,
                      textDecoration: "none",
                    }}
                    className="white-button-large page__btn page__btn--blue-border"
                    href="https://calendly.com/cyber-trust/free-advice"
                    target="_blank"
                  >
                    Schedule a Call
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default GovernanceModel
